.abc {
	color:'dfdsf'
}

#profile_modal .modal-content {
	border: none;
}

#profile_modal .modal-header { 
	background-color: #337ab7;
	border: #337ab7;
}

#profile_modal button.close {
	color: #fff;
}

.avatar-profile1 {
	margin-top: -35px;
}

.cardText {
	font-size: 13px;
}

#profile_modal .alert.alert-info {
  width: 100%;
}

#profile_modal .nav-link.active {
	background-color: #337ab7;
	color: #fff;
}

#profile_modal .nav-item {
	border: 1px solid #ccc;
	margin: 5px;
	color: black !important;
	font-weight: 600;
	font-size: 14px;
	cursor: pointer;
	border-radius: 4px;
	overflow: hidden;
}

#profile_modal .nav-item a {
	color: #5e6e82;
}
#profile_modal .row {
	margin: 0;
}
#profile_modal .modal-user-name {
	font-size: 24px;
	font-weight: 600;
	color: #337ab7;
	margin-top: 12px;
	margin-bottom: 6px;
}
#profile_modal .modal-user-title {
	display: flex;
	margin-bottom: 14px;
}
#profile_modal .modal-user-title div {
	line-height: 1;
	margin-left:  76px;
	display: flex;
	align-items: center;
}
#profile_modal .modal-user-title .title-text {
	line-height: 26px;
}
#profile_modal .modal-user-title span {
	font-size: 12px;
}
#profile_modal .user-copy-icon {
	width: 24px;
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	cursor: pointer;
	/*background-color: #337ab75e;*/
}

fieldset {
	min-height: 70vh !important;
	margin-bottom: 12px !important;
}
legend {
	margin-left: 14px;
	font-weight: 600;
	color: #2d2d2d !important;
}
.modalRightContent {
	text-align: right;
}
.modalRightContent .badge-soft-secondary{
	cursor: pointer;
}
.row .bold-text {
	font-weight: bold;
	color: #2d2d2d;
}
.row hr {
	margin-top: 0.4rem;
}
.btn-badge {
	display: flex;
	justify-content: flex-end;
}
.btn-badge .badge {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	padding: 0 !important;
	align-items: center;
	display: flex;
	justify-content: center;
	
}
.profile-sidebtn .col-lg-3{
	padding: 0 30px !important;
}
.profile-sidebtn .nav-link {
	padding: .7rem 1rem;
}

.modalHeader .modal-title, .modalHeader .close {
	color: #fff;
}
.email_modal .btn-warn{
	color : #ba8e00;
	border: 1px solid #ba8e00;
	font-weight: 400;
	font-size: 14px;
	margin: 10px 0 0px;
	padding: .2rem .5rem !important;
}
.email_modal .btn-warn-click{
	color : #fff;
	background-color: #ba8e00;
	border: 1px solid #ba8e00;
	padding: .2rem .5rem !important;
	font-weight: 400;
	font-size: 14px;
	margin: 10px 0 0px;
}

.email_modal .btn-warn-click:hover {
	color: #fff;
}
.email_modal .btn-warn:hover{
	color : #ba8e00;
}
.userProfileModalCopy {
	cursor: pointer;
}

#profile_modal .shadow-none.border.rounded-0.card {
	margin: 10px 10px;
}
#profile_modal .activeMemebrShipHeader, #profile_modal .activeMemebrShipHeader span   {
	background-color: #337ab7 !important;
	border-color: #337ab7 !important;	
	color: #fff !important;
}

#profile_modal .card-header {
	border-radius: 0 !important;
}
#profile_modal .deActiveMemebrShipHeader {
	background-color: #F1F1F1 !important;
}
#profile_modal .openHideAccordian {
	float: right;
}
.acc-tab-details-list  {
	padding: 0;
}
.acc-tab-details-list li {
display: flex;
flex-wrap: wrap;
align-items: baseline;
padding: 6px 0;
}
.acc-tab-details-list li h6 {
	width: 110px;
}
.acc-tab-details-list li h6,
.acc-tab-details-list li span,
.acc-tab-details-list li a {
	font-size: 14px;
}
.acc-tab-details-list li .badge {
  height: 28px;
  width: 28px;
	border-radius: 50%;
	margin-left: 18px;
	padding: 0 !important;
	display: flex;
	align-items: center;
	justify-content: center;
}
.acc-tab-details-list li span label {
	font-size: 14px;
	font-weight: 400 !important;
}
.qr-code img {
 width: 100px;
 height: 100px;
}
.qr-code p{
	font-size: 14px;
}
.profile-card-info .col-lg-3{
	padding: 0 0.5rem !important;

}
.border-lr {
	border-left: 1px solid #d2d2d2;
	border-right: 1px solid #d2d2d2;
}
.profile-card-wallet-pass img{
		width: auto;
		height: 80px;
}
.profile-card-wallet-pass .blue-box {
	padding: .6rem .8rem;
	background-color: #deecf2;
	border-radius: 5px;
}
.profile-card-wallet-pass .content-box span{
  font-size: 12px;
}
.profile-card-wallet-pass .content-box h4 {
	font-size: 24px;
	margin: -4px 0 0;
}
.profile-card-wallet-pass button {
	background-color: #337ab7;
	color: #fff;
	border-color: #337ab7;
}
.profile-card-membership ul {
	padding: 0;
	margin-bottom: 0;
}
.profile-card-membership ul li {
	position: relative;
}
.profile-card-membership ul li::before {
content: '';
position: absolute;
left: -15px;
width: 8px;
height: 8px;
border-radius: 50%;
background-color: #d7d7d7;
top: 13px;
}
.profile-card-membership ul li h4 {
	font-size: 12px;
	margin: 0;
	margin-top: 10px;
	display: flex;
}
.profile-card-membership ul li h4 span{
	font-weight: 400;
	margin-left: .4rem;
}
.profile-card-membership .membership-icon {
	width: 95px;
	text-align: right;
	margin-top: 2px;
}
.profile-card-membership .membership-icon span {
	width: 30px !important;
	height: 30px !important;
	border-radius: 50%;
	line-height: 1.7;
	text-align: center;
}
.profile-card-membership .membership-icon .bg-trash {
	background-color: #e637574d;
}
.profile-card-membership .membership-icon .bg-trash .fa-trash-alt {
	color: #e63757 !important;
}
.profile-card-membership .pc-ms-border-right {
	border-right: 1px solid #d7d7d7;
}
.profile-card-membership .right-side li {
margin-left: 15px;
}
.profile-card-membership .right-side li h4 span label {
	font-weight: 400 !important;
	font-size: 12px !important;
}
#profile_modal .activeMemebrShipHeader .updownArrow {
	padding: 0px 6px;
background-color: #f2f2f270 !important;
	border-radius: 50% !important;
	line-height: 25px;
}
#profile_modal .deActiveMemebrShipHeader .updownArrow {
	padding: 0px 6px;
background-color: #337ab738 !important;
	border-radius: 50% !important;
	line-height: 25px;
}
#profile_modal .deActiveMemebrShipHeader .trashIcon,
#profile_modal .activeMemebrShipHeader .trashIcon {
	padding: 3px 6px;
font-size: 12px;
background-color: #f2f2f270 !important;
	border-radius: 50% !important;
	margin-right: 6px;
	position: relative;
top: -2px;
}
#profile_modal .deActiveMemebrShipHeader .trashIcon {
	background-color: #337ab738 !important;
}
#profile_modal .deActiveMemebrShipHeader .headerText {
	position: relative;
	color: #337ab7;
	font-size: 12px;
	margin-right: 6px;
}
#profile_modal .activeMemebrShipHeader .headerText {
	position: relative;
	color: #fff;
	font-size: 12px;
	margin-right: 6px;
}
#profile_modal .deActiveMemebrShipHeader .headerText::before {
	content: '';
	position: absolute;
	width: 10px;
	height: 10px;
	background-color: #337ab7;
	left: -16px;
  border-radius: 50%;
  top: 4px;
}
#profile_modal .activeMemebrShipHeader .headerText::before {
	content: '';
	position: absolute;
	width: 10px;
	height: 10px;
	background-color: #fff;
	left: -16px;
  border-radius: 50%;
  top: 4px;
}
.memebrShipDiv {
	margin-top: 4px;
}
.memebrAdmin {
	position: relative;
	
}

.shadow-none.border.rounded-0.card {
    width: 100%;
}
.membership-icon .badge {
	display: inline-block !important;
}
.userProfileDateBirth .rdtPicker {
	bottom: inherit;
}

@media (max-width: 992px) {
	.profile-card-membership .pc-ms-border-right {
	border-right: 0px solid #d7d7d7;
}
.profile-card-membership .right-side li {
  margin-left: 0px;
}
.card-header .text-sans-serif {
	font-size: 15px;
}
#profile_modal .card-header {
	padding-right: 8px !important;
}
.acc-tab-details-list li h6, 
.acc-tab-details-list li span, 
.acc-tab-details-list li a {
  font-size: 13px;
}
.border-lr {
	border-left: 0px solid #d2d2d2;
border-right: 0px solid #d2d2d2;
}
.acc-tab-details-list li .badge {
  margin-left: 10px;
}
.acc-tab-details-list{
	margin: 0;
}
.ms-card-bottom .col-lg-4 {
	padding: 6px 0;
}
}