.payment_type_div, .payment_div {
    border: 1px solid #ccc;
    padding: 15px;
    border-radius: 20px;
}

.payment_type_div {
    margin-top: 10px;
}

.payment_div .amount {
    font-size: 0.75rem;
}
.title_text {
    font-size: 0.75rem;
}

input.form-control.amount {
    padding: 0.1rem 0.3rem;
    /*height: 22px;*/
   /* width: 50%;*/
    text-align: right;
    margin-left: 3px;
    display: inline-block;
}
span.currency_symbol {
    position: absolute;
    left: -2px;
    top: 5px;
    font-size: 0.75rem;
}
.currency_text {
    margin-left: -20px;
}
.currency_symbol.currency_text {
    position: unset;
    margin-top: 7px;

}

input.form-control.activate_account {
    padding: 0.1rem 0.3rem;
    /*height: 22px;*/
    margin-left: 3px;
    display: inline-block;
    /*width: 50%;*/
}

input.password_field {
    font-size: 12px;
    padding-right: 25px !important;
    border: none;
}

input.password_field:focus {
    border-color: #d8e2ef;
    box-shadow: none;
}

span.curreny-icon {
    display: inline-block;
    position: relative;
    top: 2px;
}
.bank_type {
    border: 1px solid #ccc;
    margin: 2px 0px 2px 14px;
    text-align: center;
    padding-top: 10px;
    border-radius: 10px;
    cursor: pointer;
    padding-bottom: 10px;
}
.stripe_payment_top_bar {
    max-width: 600px !important;
    padding: 5px !important;
    margin: 5px;
    /*background-color: yellow;*/
    border: 1px solid rgba(0,0,0,0.15);
    box-shadow: 0 2px 2px rgb(0 0 0 / 15%);
    text-align: center;padding: 5px;font-weight: bold;font-size: smaller;
}

.payment-navbar-top {
    position: unset;
    top: 0;
    font-size: .83333rem;
    font-weight: 600;
    margin-left: -1rem;
    margin-right: -1rem;
}

.stripe_payment_layout {
    max-width: 600px !important;
    padding: 5px !important;
    margin: 0px auto 10px;
}
span.total-amount {
    font-weight: 600;
}
.bank_type.selected {
    border-color: blue;
    color: blue;
}
.member-info{
    position: relative;
    top:-5px;
}

.payment_type_criteria {
    font-size: 0.688rem;
}

.totalAmount {
    padding: 5px 5px 5px 0px;
}
.important-notes {
    font-size: 0.75rem;
    font-style: italic;
    line-height: 1.2;
}

.bank_type_inner_div {
    width: 100%;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}

#payment-message {
    color: red;
}

span.member-info-right {
    float:right;
}

.stripe_payment_layout .navbar-brand {
    margin-right: 0;
}

.cetnerCreateAccount {
    margin: 10px 15px;
    font-size: .83333rem;
}

.terms_privacy_policy_ {
    cursor: default;
}
.legal {
    font-size: 0.7rem;
}
.cardSpinnerImage {
    position: absolute;
    top: 10px;
    left: 22px;
    right: 0;
    bottom: 0;
}
img.rounded-circle.cardSpinnerProcessingImage {
    position: absolute;
    right: 10px;
    top: -20px;
}
.bank_type {
    min-height: 4rem;
}
.formValidationError {
    text-align: left;
}
i.fas.fa-eye.showHideEyeBall {
    position: absolute;
    right: 15px;
    top: 9px;
    cursor: pointer;
    font-size: 12px;
}
@media only screen and (max-width:767px){
   .bank_type {
    margin: 2px 5px 6px;
    padding: 5px 0;
    max-width: 29% !important;
    flex-basis: 29% !important;
}
    .payment_type_criteria{
        font-size: 0.688rem;
        margin-bottom: 5px;
    }
    .payment_type_div .row {
    justify-content: center;
}
.payment_div h4 {
    font-size: 1.25rem;
}
.loginLogo .navbar-brand {
    position: unset;
    text-align: center !important;
}
.loginLogo .navbar-brand > div {
    justify-content: center;
}
.content {
    /*padding-top: 10px !important;*/
}
.bank_type_inner_div{
    flex-wrap: wrap;
}
.payment_type_div .amount {
    font-size: 0.688rem;
    line-height: 1.4;
    display: inline-block;
}
.member-info.member-info-full-width.col-12 {
    text-align: center;
}
span.member-info-right {
    float: none;
    display: block;
}
}

@media only screen and (max-width:575px){
    .formValidationError {
        font-size: 0.6rem !important;
    }
    .userName {
       font-size: 0.7rem !important; 
    }
}

.userName {
    word-break: break-all;
    line-height: 1;
    display: inline-block;
}


@media only screen and (max-width:428px){
    .formValidationError {
        font-size: 0.6rem !important;
    }
    .userName {
       font-size: 0.7rem !important; 
    }
}

@media only screen and (max-width:320px){
    .formValidationError {
        font-size: 0.5rem !important;
    }
    .userName {
       font-size: 0.5rem !important; 
    }
}


@media screen and (-webkit-min-device-pixel-ratio:0) {
    input.amount {
        padding-right: 10px !important;
    }
}